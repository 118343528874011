/* 



  
  /* Container for the form itself 
  .container-form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 780px; /* Make this max-width the same as your style3 width 
  }
  
  /* Header styling 
  header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #f04c24;
  }
  
  /* Label styling 
  .form-label {
    font-weight: 500;
    color: #555;
  }
  
  /* Input field styling 
  .form-control {
    border: 1px solid #f6a995;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    color: #333;
  }
  
  /* Button styling 
  .btn-primary {
    background-color: #f04c24;
    border-color: #f04c24;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover {
    background-color: #b02c0b;
    border-color: #f04c24;
  }
  
  .main-con {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(http://localhost:3000/static/media/back-image.6788b69a662781c9ca33.jpg) no-repeat center center;
    background-size: cover;
    background-attachment: fixed; /* Ensures the image stays fixed on scroll 
  }
  
  /* Responsive adjustments 
  @media (max-width: 1500px) {
    .main-con {
      background-size: contain; /* Adjust to ensure full image is visible 
      background-position: center center;
    }
  }

  @media (max-width: 1200px) {
    .main-con {
      background-size: contain; /* Adjust to ensure full image is visible 
      background-position: center center;
    }
  }
  
  @media (max-width: 992px) { 
    .main-con {
      background-size: contain; /* Adjust to ensure full image is visible 
      background-position: center center;
    }
  }
  
  @media (max-width: 768px) {
    .main-con {
      background-size: contain; /* Adjust to ensure full image is visible 
      background-position: center center;
    }
  }
  
  @media (max-width: 576px) {
    .main-con {
      background-size: contain; /* Cover the screen again for smaller devices 
      background-position: center center;
    }
  }
  
 

 /* Base styles for the form 
.main-con {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa; /* Light background 
}

.container-form {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure it doesn't overflow on small screens 
}

header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

label {
  font-weight: bold;
}

input.form-control {
  border-radius: 5px;
}

/* Responsive adjustments */

/* Small devices (landscape phones, 576px and up) 
@media (min-width: 576px) {
  .container-form {
    max-width: 540px;
  }

  header {
    font-size: 1.75rem;
  }
}

/* Medium devices (tablets, 768px and up) 
@media (min-width: 768px) {
  .container-form {
    max-width: 720px;
  }

  header {
    font-size: 2rem;
  }
}

/* Large devices (desktops, 992px and up) 
@media (min-width: 992px) {
  .container-form {
    max-width: 960px;
  }

  header {
    font-size: 2.25rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) 
@media (min-width: 1200px) {
  .container-form {
    max-width: 1140px;
  }

  header {
    font-size: 2.5rem;
  }
} */
.main-con {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 15px;
  background-color: #f8f9fa;

  justify-content: center;
  background: url(https://i.postimg.cc/QMFfwsfG/back-image.jpg) no-repeat;
  background-size: cover;
  
}


/* Container for the form itself */
.container-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 780px; /* Make this max-width the same as your style3 width */
}

/* Header styling */
header {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #f04c24;
}

/* Label styling */
.form-label {
  font-weight: 500;
  color: #555;
}

/* Input field styling */
.form-control {
  border: 1px solid #f6a995;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #333;
}

/* Button styling */
.btn-primary {
  background-color: #f04c24;
  border-color: #f04c24;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #b02c0b;
  border-color: #f04c24;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container-form {
    padding: 20px;
  }

  header {
    font-size: 20px;
  }

  .form-control {
    padding: 8px;
    font-size: 14px;
  }

  .btn-primary {
    padding: 10px 15px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .main-con {
    padding: 30px 10px;
  }

  .container-form {
    padding: 15px;
  }

  .form-label {
    font-size: 14px;
  }

  .btn-primary {
    padding: 8px 10px;
    font-size: 14px;
  }
}
