

#cards{
    margin-top: "-110px";
    z-index: "2";
    position: "relative";
}
.card{
    cursor: pointer;
}
.pa-m{
   padding: 10px 20px;
}
@media screen and (max-width: 480px) {
    #cards .row {
      display: none !important;
    }
  }