#inner-coursepage .nav_ul li {
    padding: 0 0.7rem;

}
#inner-coursepage .nav_ul a {
    font-size: 17px;
    color: #192f60;
    background-color: #fff;
    font-weight: 400;
    letter-spacing: 0.1px;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
}
#inner-coursepage .nav_ul a:hover {
    background-color: #f04c24;
    box-shadow: 0px 4px 10px #f04c24;
    color: white;
}

#inner-coursepage .btn2 {
    position: relative;
    border-radius: 8px;
    border: 0;
    background: #f1f2f5;
    padding: 0.5rem 1.2rem;
    text-decoration: none;
    font-size: 1.1rem;
    color: #000;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
#inner-coursepage .btn2:hover {
    box-shadow: 0px 4px 10px #f04c24;
    background-color: #f04c24;
    color: #fff;
}
@media screen and (max-width: 1200px) {
    #inner-coursepage .btn2 {
        font-size: 1rem;
    }

    #inner-coursepage .nav_ul a {
        font-size: 1.1rem;
    }
}
#inner-coursepage .desktop-content{
    display: block;
 }
@media screen and (max-width: 1100px) {
    #inner-coursepage .btn2 {
        font-size: 0.85rem;
    }

    #inner-coursepage .nav_ul a {
        font-size: 0.95rem;
    }
    #inner-coursepage .navbar-text {
    font-size: 18.5px;
    margin-left: 50px;
    margin-top: -8px;
 }
 #inner-coursepage .desktop-content{
    display: none !important;
 }
 #inner-coursepage .cp-des{
    padding-right: 0px !important;
    /* padding-left: 0px !important; */
 }
 #inner-coursepage .cp-img{
    padding-right: 0px;
    padding-left: 0px;
 }
}
#inner-coursepage .upper {
    height: 43px;
 }
 #inner-coursepage i {
    cursor: pointer;
}
#inner-coursepage  .modal-title {
    color: #333 !important;
}
#inner-coursepage .lower {
    height: 75px;
}

/* Faq section */

#inner-coursepage #faq{
    margin-top: 60px;
}




.accordion-button:focus {
    box-shadow: none !important;
    border: none !important;
  }
  
.accordion-button:hover {
    background-color:#f04c24;
    color: white;
  }

  .accordion-button:not(.collapsed) {
    background-color: #f04c24 !important;
    border: none !important;
    color: white;
  }

 .whybi .card-body h5 {
    font-size: 1.24rem !important;
  }




  @media screen and (max-width: 992px) {
    #inner-coursepage .cp-des{
        padding-right: 0px !important;
        padding-left: 0px !important;
     }
}