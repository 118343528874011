#partnertype .container {
    max-width: 1200px;
    padding: 0 15px;
    /* Add padding for smaller screens */
  }
  
  #partnertype .head {
    font-size: 2.5rem !important;
    font-weight: 700 !important;
    letter-spacing: .7px;
    line-height: 1.2;
    margin-bottom: 60px !important;
  }
  
  #partnertype .card {
    border: none;
    height: 100%;
    /* Ensure the card takes up full height of its container */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background: rgb(250, 249, 254);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Distribute space between the title, text, and button */
  }
  
  #partnertype .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  #partnertype .card-title {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  #partnertype .card-text {
    color: black;
    flex-grow: 1;
    /* Ensure the text takes up available space, pushing the button to the bottom */
  }
  
  #partnertype .btn-primary {
    background-color:rgb(239, 75, 35);
    border: none;
    width: auto;
    height: auto;
  
    font-size: 1rem;
    /* Adjust font size if needed */
    font-weight: 600;
  }
  
  .card-body i {
    font-size: 10px;
  }
  
  /* Responsive adjustments */
@media (min-width: 992px) {
  #partnertype .container {
    margin-top: -50px !important;
  }
}


  @media (max-width: 992px) {
    #partnertype .head {
      font-size: 2.3rem !important;
      margin-bottom: 50px !important;
    }
  
    #partnertype .card-title {
      font-size: 1.5rem !important;
    }
  
    #partnertype .card-text {
      font-size: 1.1rem !important;
    }
  
    #partnertype .btn-primary {
      padding: 10px 15px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    #partnertype .container {
      margin-top: -30px !important;
    }


    #partnertype .head {
      font-size: 2.2rem !important;
      margin-bottom: 40px !important;
    }
  
    #partnertype .card-title {
      font-size: 1.1rem;
    }
  
    #partnertype .card-text {
      font-size: 0.9rem;
    }
  
    #partnertype .btn-primary {
      padding: 10px 15px;
  
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    #partnertype .container {
      margin-top: -15px !important;
    }

    #partnertype .head {
      font-size: 2rem !important;
      margin-bottom: 30px !important;
    }
  
    #partnertype .card {
      height: auto;
      padding: 20px;
      margin-bottom: 3px !important;
    }
  
    #partnertype .card-title {
      font-size: 1.6rem !important;
    }
  
    #partnertype .card-text {
      font-size: 0.85rem;
    }
  
    #partnertype .btn-primary {
      padding: 6px 10px;
      font-size: 1rem !important;
    }
  }
  