.student .dashboard-container {
  padding: 0;
  margin: 0;
 
  width:95%
}
.student .nav-item .nav-link:hover{
color: orangered;
}
.student .sidebar {
  height: 100%;
  position: fixed;
  padding: 20px;
}

.student .logo {
  font-weight: bold;
  margin-bottom: 20px;
}

.student .sidebar .nav-link {
  font-size: 1.1rem;
  padding: 10px;
  margin: 10px 0;
  color: #000;
}

.student .sidebar .nav-link i {
  margin-right: 10px;
}

.student .sidebar-upgrade {
  position: absolute;
  bottom: 30px;
}

.student .main-content {
  margin-left: 250px;
  padding: 20px;
}

.student .header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.student .search-input {
  width: 300px;
}

.student .stat-card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.student .stat-card .icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: #ccc;
}

.student .course-card .progress {
  height: 5px;
  background-color: #e9ecef;
}

.student .course-card .progress-bar {
  background-color: #007bff;
}

.student .class-card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.student .daily-progress button {
  border-radius: 10px;
}

.student .upcoming-classes .card {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.student .icon-container {
  background-color: #000; 
  padding: 10px; 
  border-radius: 12px; 
  display: inline-flex; 
  align-items: center;
  justify-content: center;
}

.student .icon-container i {
  color: #fff; 
  font-size: 16px;
}

.student.stat-card h5 {
  font-weight: bold;
}

.student.stat-card p {
  color: #aaa; 
}

