@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
 .font-roboto{
    font-family: "Roboto", sans-serif;
}
#contact-page .banner-img img{
    width: 100%;
    height: 200px;
}
.icon-sec i{
    padding: 10px 12px;
    border-radius: 50%;
    background-color: #f04c24;
    color: white;
}
#contact-page h1{
    color: #f04c24;
}
/* Submit button css */

#contact-submit-btn button:hover{
    background-color: #f04c24;
    border-color: #f04c24;
}

#contact-page .contact-banner img{
    width: 90% !important;
    padding-left: 100px;
}

/* Responsive css */

@media screen and (min-width: 1100px) {
    #contact-page .contact-banner img{
        height: 400px !important;
    }
    #contact-page .contact-container .row{
        height: 500px;
    }
  }

  @media screen and (max-width: 600px) {
    #contact-page .contact-banner{
        margin-top: 50px;
    }


    #contact-page .icon-sec .col-4{
        width: 100% !important;
        display: flex !important;
        justify-content: start !important;
        margin-top: 20px;
    }
    #contact-page .contact-banner {
        display: flex;
        justify-content: center;
    }
    #contact-page .contact-banner img{
        width: 250px !important;
        padding-left: 0px;
    }
  }
