.blogcomponent .scrollable-container {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px;
}

.blogcomponent .card {
    margin-bottom: 20px;
    overflow: hidden;
}

.blogcomponent .card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blogcomponent .main-box {
    margin-left: 10px;
}


.blogcomponent .large-heading {
    font-size: 3rem;
    width: 100%;
    height: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.blogcomponent .scrollable-container .card {
    width: 100%;
    height: auto;
    border: 1px;
    border-radius: 15px;
}

.blogcomponent .scrollable-container .card img {
    height: 315px;
    border-radius: 15px;
    object-fit: cover;
}

.blogcomponent .box_30 {
    width: 30px;
    height: 30px;
    font-family: Rubik;
    line-height: 29.87px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.blogcomponent .box_may {
    width: 40px;
    height: 24px;
    font-family: Rubik;
    line-height: 23.9px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.blogcomponent .header_logo {
    width: 357px;
    height: 36.62px;
    border: 1px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}

.blogcomponent .card-title {
    Height: 24px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.9px;
    letter-spacing: 0.17em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.blogcomponent .card-text {
    width: 312px;
    height: 30px;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 500;
    line-height: 29.87px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}



.blogcomponent .card-body-foot {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;

}

.blogcomponent .card-body-para {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 23.9px;
}

.blogcomponent .card-body-span {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.9px;
}




/* Slick carousel styles */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Base style for all dots */
.blogcomponent .slick-dots li button:before {
    content: '';
    display: inline-block;
    width: 12.75px;
    height: 12.75px;
    border-radius: 50%;
    background: #C4C4C4;
    transition: all 0.3s ease;
}

/* Active dot style */
.blogcomponent .slick-dots li.slick-active button:before {
    width: 44px;
    height: 12.75px;
    border-radius: 25px;
    background: #BE1E2D;
}

/* Dots container positioning */
.blogcomponent .slick-dots {
    bottom: -30px;
}

.blogcomponent .slick-dots li {
    margin: 40px 5px;
}



/* Media Queries for Responsiveness */
@media (min-width: 1201px) {
    .blogcomponent .card-title {
        font-size: 20px;
       
    }

    .blogcomponent .card-text {
        font-size: 21px;
        text-align: center;
        justify-content: center;
    }

    .blogcomponent .card-body-para,
    .blogcomponent .card-body-span {
        font-size: 20px;

    }
}

@media (max-width: 1200px) {
    .blogcomponent  .scrollable-container .card img {
        height: 315px;
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
    }


    .blogcomponent  .card-title {
        font-size: 20px;
    }

    .blogcomponent  .card-text {
        font-size: 23px;
        text-align: center;
        justify-content: center;
    }

    .blogcomponent .card-body-para,
    .blogcomponent .card-body-span {
        font-size: 20px;

    }
}

@media (max-width: 768px) {
    .blogcomponent .scrollable-container .card img {
        height: 250px;
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
    }


    .blogcomponent .card-title {
        font-size: 20px;
    }

    .blogcomponent .card-text {
        font-size: 16px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .blogcomponent .card-body-para,
    .blogcomponent .card-body-span {
        font-size: 15px;

    }
}


@media (max-width: 480px) {

    .blogcomponent .header_logo {
        width: 100%;
        height: 36.62px;
    }

    .blogcomponent .scrollable-container .card img {
        width: 100%;
        height: 250px;
        border-radius: 15px;
    }

    .blogcomponent .card-title {
        font-size: 20px;
    }

    .blogcomponent .card-text {
        font-size: 20px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

    }
    .blogcomponent .card {
        height:300px;
    }

    .blogcomponent .card-body-para,
    .blogcomponent .card-body-span {
        font-size: 18px;
        text-align: center;
    }
}