.dashboardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color:#E9ECEF !important;
  height: 100%;
}

.rightContainer {
  padding-left: 200px; /* Match the width of the sidebar */
  background-color:#E9ECEF !important;
}

@media (max-width: 912px) {
  .dashboardContainer {
    flex-direction: column;
    height: 100%;
  }

  .rightContainer {
    padding-left: 2%; /* Match the width of the sidebar */
    padding-right: 2%;
  }
}