.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  justify-content: center;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 253.23px;
  height: 370px;
  margin: 10px;
  padding: 10px;
  margin-top: 70px;
  border: 1px solid #ccc;
  background-color: #ffff;
}

.cardImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  padding-bottom: 20px;
}

.uploadedCardImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  padding-bottom: 20px;
  opacity: 0.6;
  z-index: 0;
}

.cardImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 240px;
  width: 240px;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 1px;
  padding-right: 1px;
  border: 1.66px dotted #8A92A6;
  position: relative;
}

.uploadButton {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #f04c24;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
  z-index: 998;
}

.uploadIcon {
  font-size: 14px;
  color: #ffffff;
}

.uploadText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 5px;
  color: #ffffff;
}

.captionInput {
  width: 150px;
  height: 20px;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 20px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #8A92A6;
}

.cardIcons {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.icon {
  margin: 0 5px;
  font-size: 24px;
  color:#8A92A6;
  cursor: pointer;
}

.cardTitle {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center !important;
  color: black;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.saveButton,
 .cancelButton {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #f04c24 !important;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 110px;
  height: 30px;
  margin-right: 5px;
}

@media (min-width: 912px) {
  .container {
    justify-content: center;
  }

  .card {
    max-width: 253.23px;
  }
}

