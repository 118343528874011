/* 
.navbar {
    padding: 0 1rem;
    background-color: #fafafc;
   
    width: 100%;
    top: 0;
    z-index: 10; /* Ensure it stays on top of the banner 
}
  
  .nav_ul li {
    padding: 0 0.7rem;

}
 .nav_ul a {
    font-size: 18px;
    color: #192f60;
    background-color: #fafafc;
    font-weight: 400;
    letter-spacing: 0.1px;
    border-radius: 20px;
    transition: 0.3s ease-in-out;
    
}
.nav_ul a:hover {
    color: #f04c24;
}

 .btn2 {
    position: relative;
    border-radius: 8px;
    border: 0;
    background: #e7e9ec;
    padding: 0.5rem 1.2rem;
    text-decoration: none;
    font-size: 1.1rem;
    color: #000;
    cursor: pointer;
    transition: 0.3s ease-in-out;
   
}
 .btn2:hover {
    box-shadow: 0px 4px 10px #f04c24;
    background-color: #f04c24;
    color: #fff;
}
@media screen and (max-width: 1200px) {
 .btn2 {
        font-size: 1rem;
    }

 .nav_ul a {
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 1100px) {
     .btn2 {
        font-size: 0.85rem;
    }

    .nav_ul a {
        font-size: 0.95rem;
    }
 .navbar-text {
    font-size: 18.5px;
    margin-left: 50px;
    margin-top: -8px;
 }
}
  
  
 */

.navbar {
    padding: 0 1rem;
    background-color: #fafafc;
   
    width: 100%;
    top: 0;
    z-index: 10; 
  }
  
   .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  } 
  .nav_ul li {
    padding: 0 0.7rem;

}
  .logo {
    width: 140px;
    height: 50px;
    margin-left: 30px;
  }
 
  
  .homebutton {
    font-size: 1.8rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #192f60;
    transition: 0.3s;
    display:none;
   
  }
  
  .homebutton:hover {
    color: #f04c24;
  }
  
   .navLinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .navLinks li {
    padding: 0 1rem;
  } 
  
  .navLinks a {
    font-size: 18px;
    color: #192f60;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .navLinks a:hover {
    color: #f04c24;
  }
  
  
  .sidebar {
    position: fixed;
    top: 0;
    left: -100%; 
    width: 65%;
    height: 100%;
    background-color: #f04c24;
    transition: left 0.3s ease-in-out;
    z-index: 20;
    padding-top: 60px; 
  }
  
  .sidebar.open {
    left: 0; 
  }
  .navLinks1 a{
    font-size: 20px;
    color: black;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
   /* .navLinks1 li a:hover {
    color: #fafafc!important;
  }  */
  .navLinks1 li {
    padding:  1rem 0;
  } 
  .closebtn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2rem;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
  }
  
  .closebtn:hover {
    color: #f04c24;
  }
  
 
  .navLinks.sidebar {
    list-style-type: none;
    padding: 0;
  }
  
  .navLinks.sidebar li {
    padding: 1rem;
    text-align: left;

  }
  
  .navLinks.sidebar a {
    font-size: 1.2rem;
    color: #192f60;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
   /* .navLinks.sidebar a:hover {
    color: #f04c24 ;
  }  */
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 15;
  }
  
  /* Responsive styles */
 
  @media screen and (max-width: 1200px) {
    .btn2 {
           font-size: 1rem;
       }
   
    .nav_ul a {
           font-size: 1.1rem;
       }
       .desktopMenu {
        display: none; 
      }
      
      .homebutton {
        display: block; 
      }
   }
   @media screen and (max-width: 1100px) {
        .btn2 {
           font-size: 0.85rem;
       }
   
       .nav_ul a {
           font-size: 0.95rem;
       }
    .navbar-text {
       font-size: 18.5px;
       margin-left: 50px;
       margin-top: -8px;
    }
       .desktopMenu {
        display: none; 
      }
      .sidebar {
        width:50%;
      }
      
      
      .homebutton {
        display: block;
      }
   }

   @media screen and (max-width: 500px) {
    .desktopMenu {
      display: none; 
    }
    .sidebar {
      width:80%;
    }
    .logo {
      width: 120px;
      height: 40px;
      margin-left: 0px;
    }
    
    
    .homebutton {
      display: block;
    }
  }
  