.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 80px;
}

.tableContainer {
  width: 60%;
  margin-bottom: 20px;
}

.tableContainer .MuiPaper-root {
  overflow-x: auto;
}

.tableContainer .MuiTable-root {
  min-width: 500px;
  height: 100px !important;
}

.tableContainer .MuiTableHead-root,
.tableContainer .MuiTableBody-root {
  background-color: #f4f4f4;

}

.tableContainer .MuiTableCell-root {
  padding: 8px;
}

.tableContainer .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: none;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 40%;
  height: 300px;
  background-color: #ffffff;
}

.formHeading {
  margin-bottom: 10px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #343A40;
}

.edit{
  cursor:pointer;
}

.inputContainer {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 15px;
}

.inputContainer label {
  margin-bottom: 5px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.inputContainer input {
  padding: 8px;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background-color: #F4F4F4;

}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submitButton,
.cancelButton {
  padding: 8px 16px;
  margin-right: 10px;
  width: 20%;
  height: 40px;
  background-color: #f04c24;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.addButton {
  padding: 6px 12px;
  background-color: #f04c24;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 20px;
}

.addButton:focus {
  outline: none;
}

/* Media queries for smaller screens */
@media (max-width: 912px) {
  .container {
    padding-left: 0;
  }

  .tableContainer {
    width: 100%;
  }

  .formContainer {
    width: 90%;
  }

  .tableContainer .MuiTableBody-root {
    display: block;
    max-height: 300px;
    overflow-y: auto;
  }

  .tableContainer .MuiTableRow-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .tableContainer .MuiTableCell-root {
    width: 100%;
    padding: 8px;
  }

  .tableContainer .MuiTableCell-root:last-child {
    text-align: right;
  }

  .tableContainer .MuiTableCell-root .Delete {
    width: 80px !important;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .submitButton,
  .cancelButton{
    width: auto;
  }
}