#web-development-post .container-fluid,
#google-post .container-fluid,
#hr-post .container-fluid,
#digital-marketing-post .container-fluid,
#microsoft-post .container-fluid,
#software-quality-post .container-fluid {
    padding: 0 !important;
}
#web-development-post .banner-img img,
#google-post .banner-img img,
#hr-post .banner-img img,
#digital-marketing-post .banner-img img,
#microsoft-post .banner-img img,
#software-quality-post .banner-img img {
    width: 100%;
    height: 300px;
    margin-right: 0;
}
@media screen and (max-width: 480px) {
    #web-development-post .banner-img img,
    #google-post .banner-img img,
    #hr-post .banner-img img,
    #digital-marketing-post .banner-img img,
    #microsoft-post .banner-img img,
    #software-quality-post .banner-img img {
        width: 100%;
        height: 100%;
    }
}