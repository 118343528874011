.trending-courses {
    padding-top: 20px;
    margin-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
  }
  .trending-courses .nav .nav-item button{
    color: #f04c24 !important;
  }
  .trending-courses-heading {
    text-align: left;
  }
  
  .nav-tabs {
    margin-bottom: 10px;
  }
  
  .nav-link {
    padding: 8px 16px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    transition: background-color 0.3s;
  }
  
  .nav-link.active,
  .nav-link:hover {
    background-color: #f04c24;
    color: #fff;
  }
  
  .card-row {
    display: flex;
    justify-content: space-between;
  }
  
  .card-container {
    flex: 0 0 calc(25% - 15px); /* 4 cards per row with a 15px gap */
    margin-bottom: 20px;
  }
  
  .card-image {
    height: 158px;
    object-fit: cover;
    width: 100%;
  }
  
  .card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .nav-link{
    color: #f04c24 !important;
  }
  .star-rating {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .star-icon {
    color: #f04c24;
  }
  
  .star-icon-empty {
    color: #ccc;
  }
  
  .rating-value {
    margin-left: 5px;
    font-weight: bold;
    color: #f04c24;
  }
  
  /* .card-description {
    height: 80px;
    overflow: hidden;
  } */
  
  .empty-card {
    visibility: hidden;
  }
  .course-carousel {
    margin-top: 20px;
  }
  
  .card-row {
    margin-top: 20px;
  }
  
  .card-container {
    margin-bottom: 30px;
  }
  
  /* Styling for carousel controls */
  .carousel-control-prev,
  .carousel-control-next {
    width: 40px !important;
    height: 40px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50% !important;
    padding-top: 4px;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000 !important;
    border-radius: 50% !important;
    opacity: 1 !important;
    font-size: 200px !important;
    transform: scale(4.5) !important;
    color: white !important;
    filter: brightness(150%) !important;
  }
  
  .carousel-control-prev-icon::before,
  .carousel-control-next-icon::before {
    display: inline-block;
    position: absolute;
    top: 50%;
    content: "";
    border: solid transparent;
    border-width: 8px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: translateY(-50%);
  }
  
  .carousel-control-prev-icon {
    left: -10px !important;
  }
  
  .carousel-control-next-icon {
    right: -10px !important;
  }
  

  /* Card css */
 .card-container{
  cursor: pointer;
 }
 .card-container:hover{
    transition: 0.35s ease-out;
    transform: scale(1.05);
 }

  /* For large screens: 4 cards per row */
@media (min-width: 1200px) {
  .card-container {
    flex: 0 0 calc(25% - 15px); 
    margin-right: 15px;
  }
  .card-image {
    height: 158px; 
    width: 100%;
    object-fit: cover;
  }
  .card-container:last-child {
    margin-right: 0; 
  }
}

/* For medium screens: 3 cards per row */
@media (min-width: 1024px) and (max-width: 1199px) {
  .card-container {
    flex: 0 0 calc(33.33% - 15px); 
    margin-right: 15px;
  }
  .card-image {
    height: 140px;
    width: 100%;
    object-fit: cover;
  }
  .card-container:last-child {
    margin-right: 0; 
  }
}

/* For smaller tablet screens: 2 cards per row */
@media (min-width: 501px) and (max-width: 1023px) {
  .card-container {
    flex: 0 0 calc(50% - 15px); 
    margin-right: 15px;
  }
  .card-image {
    height: 200px; 
    width: 100%;
    object-fit: cover;
  }
  .card-container:last-child {
    margin-right: 0; 
  }
}

/* For mobile screens: 1 card per row */
@media (max-width: 500px) {
  .trending-courses {
    padding-left: 30px;
    padding-right: 30px;
  }
  .card-row {
    flex-wrap: wrap;
  }
  .card-container {
    flex: 0 0 100%; 
    margin-right: 0;
  }
  .card-image {
    height: 200px; 
    width: 100%;
    object-fit: cover;
  }
}
