
   
   .affiliate-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: white;
    margin-left: 100px;
   margin-top: 50px;
    box-shadow: 2px 2px 10px 10px #f96a3f;
    width: 85%;
    height: 400px;
   
  }


  
  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
  }
  
  .content {
    max-width: 50%;
  }
  
  .affiliateimg img {
    width: 100%;
    max-width: 450px;
    margin-top: 5px;
    margin-left: 150px;
  }
  
  .affiliatecareers-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #ff5722;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 1rem;
    margin-left: 50px;
  }
  .affiliateContent{
    width: 350px;
    margin-left: 50px;
  }
  @media screen and (min-device-width: 701px) and (max-device-width: 1200px)  {
    .affiliate-container {
      margin-left: 100px;
      width: 80%;
      height: auto;
    }
  
    .content {
      max-width: 60%;
    }
  
    .affiliateimg img {
      max-width: 400px;
      margin-left: 10px;
    }

    .affiliate-container .content-container h1{
      margin-left: 0px !important;
      margin-top: 20px !important;
    }

    .affiliate-container .content-container .affiliateContent{
      margin-left: 0 !important;
    }

    .affiliate-container .content-container .affiliatecareers-button{
      margin-left: 0 !important;
    }
    .affiliate-container .col-4{
      width: 60% !important;
    }
    .affiliate-container .col-8{
      width: 40% !important;
      display: flex;
      align-items: center;
    }
  
  }
  
 
  @media screen and (min-device-width: 501px) and (max-device-width: 700px) {
    .affiliate-container {
      flex-direction: column;
      margin-left: 30px;
      margin-right: 30px;
      width: 100%;
      height: auto;
    }
  
    .content-container {
      flex-direction: column;
      align-items: center;
    }

    .affiliate-container .content-container h1{
      margin-left: 0px !important;
      margin-top: 20px !important;
    }

    .affiliate-container .content-container .affiliateContent{
      margin-left: 0 !important;
    }

    .affiliate-container .content-container .affiliatecareers-button{
      margin-left: 0 !important;
    }
    .affiliate-container .col-4{
      width: 50% !important;
    }
    .affiliate-container .col-8{
      width: 50% !important;
    }
  
    .affiliate-container .content {
      max-width: 100%;
      text-align: center;
    }
  
    .affiliateimg img {
      max-width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }
  
    .careers-button {
      margin-left: 0;
    }
  }
  
  
  @media (max-width: 500px) {
    .affiliate-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      background-color: white;
      text-align: center;
      margin-top: 50px;
      margin-left: 20px;
      box-shadow: 2px 2px 10px 10px #f96a3f;
      width: 90%;
      height: auto;
    }
    .content-container {
      padding: 0;
    }
  
    .content h1 {
      font-size: 1.5rem;
    }

    .affiliate-container .content-container h1{
      margin-left: 0px !important;
      margin-top: 20px !important;
    }

    .affiliate-container .content-container .affiliateContent{
      margin-left: 0 !important;
    }

    .affiliate-container .content-container .affiliatecareers-button{
      margin-left: 0 !important;
    }
  
    /* .content p {
      font-size: 1rem;
    } */
    .affiliateContent p{
       font-size: 1rem;
    }
  
    .affiliateimg img {
      /* max-width: 100%;
      margin-top: 10px; */
      display: none;
    }
    .affiliateContent{
      width: 100% !important;
    }
    .careers-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
    .affiliate-container .col-4{
      width: 100% !important;
    }
  }