/* ModalPage.css */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

/* * {
    box-sizing: border-box;
} */

#get-start .modal-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

#get-start .modal-content {
    width: 700px;
    margin-top: 100px;
    margin-left: -100px;
    border: #f04c24 2px solid;
}

#get-start .modal-body {
    position: relative;
}

#get-start h1 {
    font-weight: bold;
    margin: 0;
}

#get-start h2 {
    text-align: center;
}

#get-start p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

#get-start span {
    font-size: 12px;
}

#get-start a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

#get-start button {
    border-radius: 20px;
    border: 1px solid white;
    background-color: #f04c24;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

#get-start button:active {
    transform: scale(0.95);
}

#get-start button:focus {
    outline: none;
}

#get-start button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

#get-start form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

#get-start input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

#get-start .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    max-width: 100%;
    width: 800px;
    min-height: 450px;
}

#get-start .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

#get-start .sign-in-container {
    left: 0;
    width: 53%;
    z-index: 2;
}

#get-start .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

#get-start .sign-up-container {
    left: 0;
    width: 57%;
    opacity: 0;
    z-index: 1;
}

#get-start .container.right-panel-active .sign-up-container {
    transform: translateX(83%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

#get-start .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

#get-start .container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

#get-start .overlay {
    background: rgb(220,159,28);
    background: radial-gradient(circle, rgba(220,159,28,1) 0%, rgba(240,76,36,1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

#get-start .container.right-panel-active .overlay {
    transform: translateX(50%);
}

#get-start .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

#get-start .overlay-left {
    transform: translateX(-20%);
}

#get-start .container.right-panel-active .overlay-left {
    transform: translateX(0);
}

#get-start .overlay-right {
    right: 0;
    transform: translateX(0);
}

#get-start .container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

#get-start .social-container {
    margin: 20px 0;
}

#get-start .social-container a {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
    transition: transform 0.4s ease-in-out;
}

#get-start .social-container a:hover {
    text-decoration: none;
    background-color: #2b39ff;
    color: #fff;
    transform: translateY(-5px) scale(1.1);
}

.get-started-mobile{
    display: none;
}
#get-start{
    display: block;
}


/* Mobile Responsive */

@media only screen and (max-width: 600px) {
    .get-started-mobile{
        display: block;
    }
    #get-start{
        display: none;
    }
    /* .login{
        display: none;
    } */
  }
