.jobs img{
    width: 400px;
}
/* .jobs h1{
    color: ff5722
} */

.job-btn{
    background-color:#f04c24 ;
    color: white;
    font-size: 18px;
    border-radius: 30px;
    padding: 5px 20px 8px 20px;
}

.job-btn:hover{
    background-color: #c04e31;
    color: white;
}


@media only screen and (max-width: 600px) {
    .jobs .mobile-col{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .jobs img{
       width: 330px; 
    }
  }