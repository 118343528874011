/* ForgetPasswordForm.module.css */

.pageContainer {
  display: flex;
  height: 100vh;
}

.topImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
}

.loginContainer {
  flex: 1;
  display: flex;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
}

.rightContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightImage {
  width: 100%;
  height: 100%;
}

.logoContainer {
  margin-bottom: 20px;
}

.logo {
  width: 92px;
  height: 77px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
  font-size: 14px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.otpContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.input {
  margin: 0 5px ;
  padding: 5px ;
  border: 2px solid #f04c24 ;
  height: 40px ;
  width: 100% ;
  text-align: left ;
  font-size: 15px ;
  outline: none ;
}

.inputotp
{
  margin: 0 5px ;
  padding: 5px ;
  border: 2px solid #f04c24 ;
  height: 60px ;
  width: 60px;
  text-align: center ;
  font-size: 18px ;
  outline: none ;
}

.button {
  padding: 5px 10px;
  background-color: #f04c24;
  color: white;
  width: 40%;
  margin-bottom: 10px;
  margin-top: 30px;
}

.button:disabled,
.linkButton:disabled {
  cursor: not-allowed;
  background-color: gray;
  color: #ccc;
}

.errorMessage {
  margin-top: 5px;
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
  text-align: left;
  width: 100%;
}

.linkButton {
  color: #f04c24;
  text-decoration: none;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .topImage {
    width: 200px;
    height: 200px;
  }
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .leftContainer {
    flex: 1;
    width: 100%;
  }

  .rightContainer {
    display: none; /* Hide the right container on mobile screens */
  }

  .loginForm {
    width: 90%;
    max-width: 320px;
  }

  .message {
    font-size: 12px;
    margin-bottom: 15px;
  }
}
