.partner-strategy {
  position: relative;
  padding: 20px;
}

/* Large Heading with Paragraph in the Left Top Corner */
.partner-strategy .heading-left h1 {
  font-size: 3.8rem !important;
  font-weight: 700 !important;
  margin-top: 20px;
  border-bottom: 3px solid rgb(239, 75, 35);
}


/* Second Heading with Paragraph in the Right Top Corner */
.partner-strategy .heading-right {
  margin-right: 270px !important;
  justify-content: center !important;
  text-align: center !important;
  width: 200px !important;
  margin-bottom: -55px !important;
}

.partner-strategy .heading-right h2 {
  font-size: 1.6rem;
  font-weight: 700;
}

.partner-strategy .heading-right p {
  font-weight: 400 !important;
  justify-content: center !important;
  text-align: center !important;
}

/* Content Section Styling */
.partner-strategy .content-section-left {
  margin-left: 110px !important;
  margin-top: 80px !important;
  width: 407px !important;
}

.partner-strategy .content-section-right {
  margin-left: -213px;
  overflow: auto;
  margin-bottom: -100px;
  margin-top: 5px !important;
  width: 350px;
  height: 250px;
}

.partner-strategy .content-section-left h3,
.partner-strategy .content-section-right h3 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.partner-strategy .content-section-left p,
.partner-strategy .content-section-right p {
  font-weight: 400;
  text-align: center;
}

/* Large Image Centered */
.partner-strategy .part-img {
  width: 500px;
  max-width: 100%;
  height: auto;
  position: absolute;
  margin: 0 auto;
  display: block;
  top: 50%;
  left: 57%;
  transform: translate(-50%, -50%);
}

/* Last Section Styling */
.partner-strategy .last-section {
  margin-top: 80px !important;
  margin-left: 380px !important;
  justify-content: center !important;
  text-align: center !important;
}

.partner-strategy .last-section h3 {
  font-size: 1.4rem;
  font-weight: 700;
}

.partner-strategy .last-section p {
  font-weight: 400;
}

/* Responsive Adjustments */
@media (min-width: 1400px) {
  .partner-strategy .container {
    margin-top: -220px !important;
  }

  .partner-strategy .heading-left h1,
  .partner-strategy .heading-right h2 {
    font-size: 1.4rem;
    /* margin-top: 60px !important; */
  }

  .partner-strategy .heading-right {
    margin-right: 322px !important;
    margin-top: 68px;
  }

  .partner-strategy .content-section-left {
    margin-left: 60px !important;
    text-align: center;
  }

  .partner-strategy .content-section-right {
    margin-left: -80 !important;
    text-align: center;
  }

  .partner-strategy .part-img {
    max-width: 650px;
    height: auto;
    left: 50%;
    top: 31%;
  }

  .partner-strategy .last-section {
    margin-left: 398px !important;
  }

  .partner-strategy #end-cont {
    margin-top: 26px !important;
    margin-left: -215px;
  }
}


@media (max-width: 1400px) {
  .partner-strategy .container {
    margin-top: -260px !important;
  }

  .partner-strategy .heading-left h1,
  .partner-strategy .heading-right h2 {
    font-size: 1.4rem;
    margin-top: 86px !important;
  }

  .partner-strategy .heading-right {
    margin-right: 198px !important;
    margin-bottom: 68px !important;
  }

  .partner-strategy .content-section-left {
    margin-left: 19px !important;
    text-align: center;
    margin-top: -41px !important;
  }

  .partner-strategy .content-section-left h3 {
    font-size: 1.4rem;
  }

  .partner-strategy .content-section-right {
    margin-left: -80 !important;
    text-align: center;
  }

  .partner-strategy #right-cont {
    margin-top: -47px !important;
  }

  .partner-strategy .content-section-right h3 {
    font-size: 1.4rem;
  }


  .partner-strategy .part-img {
    max-width: 480px;
    height: auto;
    left: 52%;
    top: 34%;
  }

  .partner-strategy .last-section {
    margin-left: 359px !important;
  }
}



/* Responsive Adjustments */
@media (max-width: 1200px) {
  .partner-strategy .container {
    margin-top: -260px !important;
  }

  .partner-strategy .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .partner-strategy .row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /* Ensure full width for all sections */
  .partner-strategy .heading-left,
  .partner-strategy .heading-right,
  .partner-strategy .content-section-left,
  .partner-strategy .content-section-right,
  .partner-strategy .last-section {
    width: 100% !important;
    max-width: 100%;
    margin: 0 auto;
  }

  .partner-strategy .heading-left h1,
  .partner-strategy .heading-right h2 {
    font-size: 1.6rem;
    margin-top: 70px !important;
  }

  .partner-strategy .heading-right {
    margin-right: 0px !important;
  }

  .partner-strategy .content-section-left,
  .partner-strategy .content-section-right {
    margin-left: 0 !important;
    margin-bottom: 20px;
  }

  .partner-strategy #end-cont {
    margin-top: -180px !important;
  }

  .partner-strategy #right-cont {
    margin-top: 6px !important;
  }

  

  .partner-strategy .part-img {
    position: relative; /* Remove absolute positioning */
    display: block;
    margin: 0 auto; /* Center the image horizontally */
    max-width: 400px; /* Control the maximum width of the image */
    height: auto;
    margin-top: 50px !important;
  }

  .partner-strategy .last-section {
    margin-left: 0 !important;
    text-align: center !important;
    margin-top: 2px !important;
  }
}

/* Further adjustments for smaller screens */
@media (max-width: 768px) {
  .partner-strategy .container {
    margin-top: -165px !important;
  }


  .partner-strategy .heading-left h1,
  .partner-strategy .heading-right h2 {
    font-size: 1.5rem;
  }

  .partner-strategy .part-img {
    display: block; /* Make image a block element */
  margin: 0 auto; /* Center horizontally */
  max-width: 400px; /* Ensure image scales with container */
  height: auto; /* Maintain aspect ratio */
  margin-top: 43px !important;
  margin-left: 5px !important;
  }

  .partner-strategy .content-section-left h3,
  .partner-strategy .content-section-right h3,
  .partner-strategy .last-section h3 {
    font-size: 1.25rem;
  }

  .partner-strategy #end-cont {
    margin-top: -185px !important;
    margin-left: 13px !important;
  }
}

@media (max-width: 576px) {
  .partner-strategy {
    display: none !important;
  }
.partner-strategy .heading-left h1 {
  font-size: 3rem !important;
  font-weight: 700 !important;
}


  .partner-strategy .heading-left h1,
  .partner-strategy .heading-right h2 {
    font-size: 1.25rem;
    text-align: center;
  }

  .partner-strategy .part-img {
    display: none;
      
    }

  .partner-strategy .content-section-left p,
  .partner-strategy .content-section-right p,
  .partner-strategy .last-section p {
    font-size: 1rem;
  }

  .partner-strategy #end-cont {
    margin-top: -94px !important;
    margin-left: 0px !important;
  }

}

  