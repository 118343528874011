/* General Styles */


.career-container {
  background-color: #f5e6e8;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  clip-path: ellipse(90% 40% at 50% 0%);
  height:1200px;
}

.styled-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f5e6e8;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;

}

.text-content h1 {
  font-size: 2.5rem;
  color: #333;
}

.carrercontent p {
   font-size: 1rem;
  color: #555;
  margin: 1rem 0;
 margin-bottom: 800px; 
 
  width: 500px; 
 
}

.text-content a {
  color: #ff6b6b;
  text-decoration: none;
}

.carrer-button {
  background-color: #333;
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.carrer-button:hover {
  background-color: #555;
}

.image-container {
  margin-top: 2rem;
  
}

.desk-image {
 width: 200px;
  height:200px;
  border-radius: 10px;
  margin-top: -700px;
}

/* Responsive Styles */
@media (min-width: 769px) {
  .styled-container {
    flex-direction: row;
    text-align: left;
  }

  .text-content {
    flex: 1;
    padding-right: 20rem;
  }

  .image-container {
    flex: 1;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .text-content h1 {
    font-size: 2rem;
  }
  .cta-button {
    padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .styled-container {
    padding: 1rem;
  }

  .text-content h1 {
    font-size: 1.5rem;
  }
  .cta-button {
    padding: 0.5rem 1rem;
  }

  .content p {
    font-size: 0.9rem;
  }
}
