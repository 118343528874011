 .top-categories {
  padding-left: 100px;
  padding-right: 100px;
  
}

.top-categories .col {
  margin: 10px;
  width: 80px;
  height: 160px;
  cursor: pointer;
  padding-top: 20px;

  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
 
  transition: transform 0.3s ease;
}

.top-categories .col:hover {
  transform: scale(1.06);
}

.top-categories .discover-categories {
  text-align: left;
}

.top-categories .container {
  display: flex;
  padding-right: 0;
  padding-left: 0;
  min-width: 90%;
}

.top-categories .row {
  flex-wrap: wrap;
}

.top-categories .col p {
  margin-bottom: 0;
  font-weight: bold;
}



/* Media queries */
@media screen {
  /* Large screen - Icon in the middle, increased size */
  .top-categories .col {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .top-categories .col i {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 576px) {
  /* Small screen - Icon to the left, increased size*/
  .top-categories .col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
  }

  .top-categories .col i {
    font-size: 2em;
    margin-right: 10px;
  }
}

.top-categories .btn {
  size: 50px;
  border: none;
  text-decoration: underline;
  color: black;
  font-weight: bold;
}

.body{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  align-items: center;
}
.card{
  cursor: pointer;
}
.pa-m{
 padding: 10px 10px;
}
 @media screen and (max-width: 480px) {
  .top-categories .col{
    height: 66px;
    
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
  }
  .top-categories {
    padding-left: 50px;
    padding-right: 50px;
  }
  .discover-categories h3{
    margin-left: -20px !important;
  }
} 

