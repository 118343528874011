
   
   .works-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: white;
    margin-bottom: 0; 
    padding-bottom: 0; 
    margin-left: 60px;
  }
  
  .works-container .content {
    margin-bottom: 0; 
  }
  
  
  .works-container + .career-related-programs {
    margin-top: 0; 
  }

  
  .workwith {
    max-width: 100%;
    height: auto;
    margin-bottom: 0; 
  }
    
  .content {
    max-width: 40%;
  }
  
  .content h2 {
    font-size: 1.5rem;
    color: black;
    margin-top: -25px;
  }
  
  .content h1 {
    font-size: 2rem;
    margin: 1rem 0;
    color: black;
    font-weight: bold;
  }
  
  .works-container .content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 1.5rem;
  }
  
  .careers-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #ff5722;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-right: 1rem;
  }
  
 .internships-link {
    color: #ff5722;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
   
  }
  .internship-blue-line2 {
    width: 170px;
    height: 2px;
    background-color: #f04c24;
  }
  
  .illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0; 
  }
  .illustration img {
    width: 545px;
}
   

@media (max-width: 1100px) {
  .careers-container {
    flex-direction: column;
    text-align: center;
  }
  .works-container{
    margin-left: 0px;
}
  
  .content {
    max-width: 100%;
  }

  .illustration {
    flex-wrap: wrap;
  }

  .person {
    margin: 0.5rem 0;
  }
}
  @media (max-width: 768px) {
    .careers-container {
      flex-direction: column;
      text-align: center;
    }
    .works-container{
      margin-left: 0px;
  }
    
    .content {
      max-width: 100%;
    }
  
    .illustration {
      flex-wrap: wrap;
    }
  
    .person {
      margin: 0.5rem 0;
    }
  }
  
  
  @media (max-width: 480px) {
    .content h1 {
      font-size: 1.5rem;
    }
    .works-container{
        margin-left: 0px;
    }
  
    .works-container .content p {
      font-size: 1rem;
    }
  
    .careers-button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  
    .illustration {
     display: none;
    }
  }
  