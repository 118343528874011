/* .intern-head{
    width:800px;
} */
.internship .intern-head h1 {
    font-size: 60px;
}

.internship .intern-head p {
    font-size: 20px;
}

.internship h2 {
    font-weight: 800;
}

.internship .card {
    width: 100%;
    height: 400px;
}

.internship .card-title {
    font-weight: 600;
    font-size: 20px;
}

.internship .intern-btn {
    background-color: #f04c24;
    color: white;
    font-size: 18px;
    border-radius: 30px;
    padding: 5px 20px 8px 20px;
}

.internship .card img {
    height: 200px;
}

/* Hover Effect */

.internship .intern-btn:hover {
    background-color: #c04e31;
    color: white;
}

.internship .card:hover {
    transition: 0.35s ease-out;
    transform: scale(1.05);
}



/* Small devices (phones, less than 576px) */
@media (max-width: 575px) {

    .internship .card {
        width: 80% !important;
        height: auto !important;
       margin: auto !important;
    }

    .intern-head h1 {
        font-size: 1.5rem !important;
        margin-bottom: 15px;
       
    }

    .intern-head p {
        font-size: 1.1rem !important;
        margin-bottom: 20px;
      
    }

    .card-title {
        font-size: 0.9rem !important;
    }

    .card-text {
        font-size: 0.8rem !important;
    }

    .internship .card-img-top {
        width: 80% !important;
        height: 200px !important;
       
    }

    .card {
        margin-bottom: 20px !important;
       
    }
}

/* Medium devices (tablets, 768px and above) */
@media (max-width: 768px) {

    

    .intern-head h1 {
        font-size: 2rem !important;
        margin-bottom: 20px;
      
    }

    .intern-head p {
        font-size: 1.2rem !important;
        margin-bottom: 25px;
      
    }

    .card-title {
        font-size: 1rem !important;
    }

    .card-text {
        font-size: .8rem !important;
    }

    .internship .card-img-top {
        height: 220px !important;
        width: 300px !important;
      
    }
}

/* Large devices (desktops, 992px and above) */
@media (max-width: 992px) {
    .intern-head h1 {
        font-size: 2.5rem !important;
        margin-bottom: 25px;
      
    }

    .intern-head p {
        font-size: 1.2rem !important;
        margin-bottom: 30px;
       
    }

    .card-title {
        font-size: 1.1rem !important;
    }

    .card-text {
        font-size: .9rem !important;
    }

    .internship .card-img-top {
        width: 100% !important;
        height: 240px !important;
       
    }
}

/* Extra large devices (desktops, 1200px and above) */
@media (min-width: 1200px) {
    .intern-head h1 {
        font-size: 3.2rem !important;
        margin-bottom: 30px;
       
    }

    .intern-head p {
        font-size: 1.3rem !important;
        margin-bottom: 35px;
       
    }

   .internship .card-title {
        font-size: 1.2rem !important;
    }

    .card-text {
        font-size: 1rem !important;
    }


}