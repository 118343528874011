 .recent-additions {
    margin-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .recent-additions-heading {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  
  .course-carousel .carousel-control-prev,
  .course-carousel .carousel-control-next {
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .course-carousel .carousel-control-prev-icon,
  .course-carousel .carousel-control-next-icon {
    background-color: #000;
    border-radius: 50%;
    opacity: 1;
  }
  
  .card-row {
    display: flex;
    justify-content: space-between;
  }
  
  .card-container {
    flex: 0 0 calc(25% - 15px); 
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 15px; 
  }
   .card-container:hover{
    transition: 0.35s ease-out;
    transform: scale(1.05);
 }
  .card-container:last-child {
    margin-right: 0; 
  }
  
  .card-image {
    height: 155px;
    object-fit: cover;
    width: 100%;
  }
  
  .card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-description {
    height: 70px;
    overflow: hidden;
  } 
  
  .empty-card {
    visibility: hidden;
  }
  
  .star-rating {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #f04c24;
  }
  
  .star-icon {
    color: #f04c24 !important;
    margin-right: 5px;
  }
    
    .carousel-control-prev,
    .carousel-control-next {
      width: 40px !important;
      height: 40px !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      background-color: rgba(0, 0, 0, 0.5) !important;
      border-radius: 50% !important;
      padding-top: 4px;
    }
    
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #000 !important;
      border-radius: 50% !important;
      opacity: 1 !important;
      font-size: 200px !important;
      transform: scale(4.5) !important;
      color: white !important;
      filter: brightness(150%) !important;
    }
    
    .carousel-control-prev-icon::before,
    .carousel-control-next-icon::before {
      display: inline-block;
      position: absolute;
      top: 50%;
      content: "";
      border: solid transparent;
      border-width: 8px;
      border-top-color: transparent;
      border-bottom-color: transparent;
      transform: translateY(-50%);
    }
    
    .carousel-control-prev-icon {
      left: -10px !important;
    }
    
    .carousel-control-next-icon {
      right: -10px !important;
    }
    


/* For larger screens: 4 cards per row */
@media (min-width: 1200px) {
  .card-container {
    flex: 0 0 calc(25% - 15px); 
  }
  .card-image {
    height: 155px; 
    width: 100%;
    object-fit: cover;
  }
  .card-container:last-child {
    margin-right: 0; 
  }
}

/* For medium screens: 3 cards per row */
@media (min-width: 1024px) and (max-width: 1199px) {
  .card-container {
    flex: 0 0 calc(33.33% - 15px); 
  }
  .card-image {
    height: 140px; 
    width: 100%;
    object-fit: cover;
  }
  .card-container:last-child {
    margin-right: 0; 
  }
}

/* For smaller tablet screens: 2 cards per row */
@media (min-width: 501px) and (max-width: 1023px) {
  .card-container {
    flex: 0 0 calc(50% - 15px); 
  }
  .card-image {
    height: 200px; 
    width: 100%;
    object-fit: cover;
  }
  .card-container:last-child {
    margin-right: 0; 
  }
}

/* For mobile screens: 1 card per row */
@media (max-width: 500px) {
  .recent-additions {
    padding-left: 30px;
    padding-right: 30px;
  }

  .card-row {
    flex-wrap: wrap;
  }

  .card-container {
    flex: 0 0 100%; 
    margin-right: 0;
  }
  .card-image {
    height: 200px; 
    width: 100%;
    object-fit: cover;
  }
}
