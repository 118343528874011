.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
}

.tableContainer {
  width: 900px;
  height: auto;
  margin-bottom: 20px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 50%;
  height: 20%;
}

.formHeading {
  margin-bottom: 10px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #343A40;
}

.inputContainer {
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 15px;
}

.inputContainer label {
  margin-bottom: 5px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.inputContainer input {
  padding: 8px;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background-color: #F4F4F4;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submitButton,
.cancelButton {
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #f04c24;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 20%;
  height: 40px;
}

.addButton {
  padding: 6px 12px;
  background-color: #f04c24;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 20px;
}

.addButton:focus {
  outline: none;
}
.searchInTable
{
  background-color: #d7d5d5;
}
.searchInput{
  color:#ADB5BD;
  background-color: #ffffff;
  width: 150px;
  height: 35px;
  padding: 10px 16px 10px 16px;
  padding-left:0;
  gap: 8px;
}

.biFilterIcon{
  margin-left: 15px;
  margin-right: 0;
  align-items: right;
  height: 20px;
  width: 20px;
}

/* Media queries for smaller screens */
@media (max-width: 912px) {
.container {
  padding-left: 0;
  overflow:hidden;
}

.tableContainer {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  background-color: #ffffff;
}

.formContainer {
  width: 90%;
}

.submitButton,
.cancelButton {
  width: auto;
}

.searchInput {
  width: 100%;
}

.biFilterIcon {
  display: none;
}
}