#K-20 .container-fluid{
    padding: 0px !important;
}
#K-20 .banner-img img{
    width: 100% !important;
}
#K-20 .page-heading h1{
    color: #f04c24;
}
#K-20 .k-20-card img {
    width: 100% !important;
    height: 250px;
    cursor: pointer;
}
/* Responsive css */
@media screen and (min-width: 1100px) {
    #K-20 .k-20-form-dec{
        width: 300px !important;
    }
    #K-20 .k-20-card img {
        margin-top:10px ;
    }
  }