 .nav_ul li {
    padding: 0 0.7rem;

}
.nav_ul li {
    padding: 0 0.5rem;

}
 .nav_ul a {
    font-size: 19px;
    color: #000000;
    font-weight: 400;
    letter-spacing: 0.7px;
    border-radius: 20px;
}
 .nav_ul a:hover {
    transition: 0.35s ease-out;
    transform: scale(1.1);
    background-color: #f04c24;
    color: white;
    border-radius: 20px;
}

 .btn2 {
    position: relative;
    border-radius: 8px;
    border: 0;
    background: #f1f2f5;
    padding: 0.5rem 1.2rem;
    text-decoration: none;
    font-size: 1.2rem;
    color: #000;
    transition: 0.3s;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
 .btn2:hover {
    background-color: #f04c24;
    color: #fff;
}
@media screen and (max-width: 1200px) {
     .btn2 {
        font-size: 1rem;
    }

     .nav_ul a {
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 1100px) {
     .btn2 {
        font-size: 0.85rem;
    }

     .nav_ul a {
        font-size: 0.95rem;
    }
}
 .navbar-text {
    font-size: 18.5px;
    margin-left: 50px;
    margin-top: -8px;
}
 .upper {
    height: 43px;
}
 
 .modal-title {
    color: #333 !important;
}
 .con {
    padding: auto;
    margin: auto;
    max-width: 100%;
    width: 1250px;
}

 .box {
    text-decoration: none;
    color: #192f60;
    font-size: 23px;
    background-color: #ffffff;
    padding: 23px 25px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px #e5e6e9;
}
 .box:hover {
    background-color: #f04c24;
}
#course-page nav form {
    box-shadow: 0px 1px 4px #e5e6e9;
    border-radius: 30px !important;
}
#course-page nav input {
    border-radius: 30px !important;
    height: 50px;
}
 img {
    margin-top: -2px;
    /* margin-left: 15px; */
    margin-right: 10px;
}

/* course heading section css */
.cp-title h1{
  color: #f04c24;
  margin-left: 6px;
  font-size: 40px;
}
.sub-title p{
  color: #42526e;
  font-size: 28px;
  margin-left: 5px;
}
/* end of course section */
  .category-head {
    margin: 30px 0;
}
  .category-head ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
  .category-title {
    flex: 0 0 calc(16.6667% - 10px);
    display: flex;
    /* justify-content: center; */
    background: white;
    padding: 6px;
    margin: 2px;
    cursor: pointer;
    transition: all 0.4s ease;
} 
 .desktop-view .category-title:hover{
  background-color: #f04c24 !important;
  color:white !important;
} 

/* mobile view hover  */
.mobile-view .category-title:hover{
  background-color: #f04c24 !important;
  color:white !important;
} 




  .category-title span {
    color: #222;
}
  .active {
    background: #f04c24;
}
  .fa-solid,
  .fa-brands {
    color: #000000;
}
/** single post **/
  .posts-main-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
}
  .posts-main-container>div {
    box-shadow: 0px 8px 22px -12px rgba(0, 0, 0, 0.64);
}
  .posts-main-container>div:hover{
    transform: scale(1.06);
    cursor: pointer;
}
  .category-name {
    position: absolute;
    top: 20px;
    left: 20px;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    padding: 4px 10px;
    border-radius: 2px;
}
  .post-content {
    padding: 25px 25px 0px 25px;
}
li:hover{
  color:#f04c24
}

  .post-content-top {
    background: #f04c24;
    color: #fff;
    padding: 5px 0 5px 15px;
    border-radius: 5px;
    box-shadow: 0px 8px 12px -12px rgba(0, 0, 0, 0.64);
}
  .post-content-top span {
    padding-right: 20px;
}
  .post-content-top   .fa-comment,
  .post-content-top   .fa-calendar {
    padding-right: 5px;
}
  .post-content h2 {
    font-size: 16px;
    padding: 12px 0;
    font-weight: bold;
}
  .post-content p {
    opacity: 0.7;
    font-size: 15px;
    line-height: 1.8;
}
  .read-btn {
    border: none;
    padding: 8px 15px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid #f04c24;
    background: transparent;
    color: #257ae9;
    border-radius: 30px;
}
  .read-btn:hover {
    background: #f04c24;
    color: white;
    border: none;
    border-radius: 30px;
   
}
/***************/

@media(max-width: 768px) {
   
    .mobile-view{
      display: block !important;
    }
    .desktop-view{
      display: none !important;
    }

    
}

@media(max-width: 968px) {
.mobile-view{
  display: block !important;
}

.cp-title {
  
  justify-content: center !important;
   text-align: center !important;               
}

.sub-title {
  
  justify-content: center !important;
   text-align: center !important;               
}


.desktop-view{
  display: none !important;
  justify-content: center;
  text-align: center;
}
}
/***********/
  .desktop-view .active {
    background-color: #f04c24;
    color: #fff;
}
.mobile-view .active{
  color:#f04c24 !important;
}
 .tabs a {
    cursor: pointer;
}


.desktop-view .fixed-col {
  position: fixed;
  top: 136px; /* Adjust based on your Navbar height */
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 15%;
  padding-top: 26px;
  z-index: 1; /* Ensure fixed-col appears above scrollable-col */
  background-color: white !important; /* Example background color */
  box-shadow: 0px 8px 25px -12px rgba(39, 1, 1, 0.64);
  border-radius: 0px;
  
}

.desktop-view  .scrollable-col {
  margin-left: 20%; /* Set margin to match the width of fixed-col */
  padding-left: 10px; /* Add padding for space between columns */
  padding-top: 130px;
}

/* menu css */

.desktop-view .fixed-col ul{
  padding-left: 0px !important;
}

.desktop-view .course-card{
  width: 10rem !important;
  background: linear-gradient(242deg, #f49cae, #b05ce2) !important;
}

/* footer css */

.desktop-view .footer-size{
  margin-left: 15% !important;
}

/* navbar css */
.desktop-view .navbar-postion{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}


/* Mobile view css */

.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

/* Responsive page */
@media screen and (max-width: 480px) {
  .navbar-text {
    font-size: 16px;
    margin-left: 0px;
    margin-top: 0px;
  }
  .cp-tab{
    display: none;
  }
  .category-title {
    flex: 0 0 calc(50% - 10px);
    display: flex;
  }
  .category-head ul{
    padding-left: 0px !important;
  }
  .cp-title h1{
    font-size: 30px !important;
    margin-top: 10px;
  }
  .sub-title p{
    font-size: 20px !important;
  }
  .mobile-view{
    display: block !important;
  }
  .desktop-view{
    display: none !important;
  }

  
}


.ul-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}







.categoriesList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-title {
  cursor: pointer;
  margin-bottom: 10px;
  /* text-align: center; */
}

#course-page ul {
  padding: 0;
  margin: 0;
}

.fixed-col {
  /* Ensures the sidebar is fixed in place */
  position: sticky;
  top: 0;
}

.scrollable-col {
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust as necessary */
}

.posts-main-container {
  display: grid;
  gap: 20px;
}

@media (min-width: 768px) {
  /* 3 columns on medium screens */
  .posts-main-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 969px) {
  /* 4 columns on large screens */
  .posts-main-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .category-title {
    justify-content: center !important;
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  /* 2 columns on small screens */
  .posts-main-container {
    grid-template-columns: repeat(2, 1fr);
  }

  /* .category-title {
    margin-left: 35px;
  } */
}

@media (max-width: 576px) {
  /* 1 column on extra small screens */
  .posts-main-container {
    grid-template-columns: 1fr;
  }

  .ul-container {
    padding-left: 20px;
  }
}





/* Grid system for categories */
.category-title {
  display: grid;
 
}


/* Three columns for screen sizes between 768px and 969px */
@media (min-width: 768px) and (max-width: 969px) {
  .ul-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .category-title {
    margin-left: 65px;
  }
}



@media (min-width: 576px) and (max-width: 768px) {

  .category-title {
    margin-left: 65px;
  }
}


@media (min-width: 440px) and (max-width: 575px) {

  .category-title {
    margin-left: 40px;
  }
}




