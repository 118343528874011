

   .google-review {
    background-color: white;
    padding: 20px;
  }
  
  .google-review-head .card-title {
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }
  .google-review .carousel-control-next, 
  .carousel-control-prev {
     opacity: 1 !important; 
}
  
 
  .user-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 0px;
  }
  
  
  .review-description {
    font-size: 16px;
    color: black;
    margin-top: 10px;
  }
  
 
  .user-name {
    font-size: 18px;
    font-weight: bold;
    color: #f04c24;
  }
  
 
  .line {
    width: 80px;
    height: 2px;
    background-color: #f04c24;
  }
  

  .icon-size {
    color: white !important;
    font-size: 20px !important;
    border-radius: 50%;
    background-color: #f04c24;
    padding: 12px 15px;
  }
  
 
  
  @media (min-width: 900px) {
    .google-review-head .card-title {
      font-size: 24px;
    }
  
    .user-image {
      width: 100px;
      height: 100px;
    }
  
    .review-description {
      font-size: 16px;
    }
    .review-description p{
      width:500px;
    }
  
    .user-name {
      font-size: 18px;
    }
  
    .icon-size {
      font-size: 20px !important;
      padding: 12px 15px;
    }
  }
  
  
  @media (min-width: 768px) and (max-width: 899px) {
    .google-review-head .card-title {
      font-size: 22px; 
    }
  
    .user-image {
      width: 80px; 
      height: 80px;
    }
  
    .review-description {
      font-size: 15px; 
    }
    .review-description p{
      width:450px;
    }
  
    .user-name {
      font-size: 16px;
    }
  
    .line {
      width: 45px;
    }
  
    .icon-size {
      font-size: 18px !important;
      padding: 10px 12px;
    }
  }
  
  @media (max-width: 767px) {
    .google-review-head .card-title {
      font-size: 20px; 
    }
  
    .user-image {
      width: 60px; 
      height: 60px;
    }
  
    .review-description {
      font-size: 14px; 
    }
    .review-description p{
      width:350px;
    }
  
    .user-name {
      font-size: 14px;
    }
  
    .line {
      width: 30px; 
    }
  
    .icon-size {
      font-size: 16px !important; 
      padding: 8px 10px; 
    }
  }
  
  
  @media (max-width: 450px) {
    .google-review-head .card-title {
      font-size: 20px; 
      text-align: center; 
    }
  
    .user-image {
      width: 80px; 
      height: 80px;
      margin: 0 auto; 
    }
  
    .review-description {
      font-size: 10px; 
      text-align: center; 
      
    }
    .review-description p{
      width:290px;
    }
  
    .user-name {
      font-size: 14px; 
      text-align: center; 
    }
  
    .line {
      width: 40px; 
      margin: 0 auto; 
    }
  
    .icon-size {
      font-size: 12px !important; 
      padding: 7px 8px; 
    }
  
    .google-review {
      padding: 10px;
    }
  }
  
  
  @media (max-width: 400px) {
    .google-review-head .card-title {
      font-size: 16px; 
      text-align: center; 
    }
  
    .user-image {
      width: 40px; 
      height: 40px;
      margin: 0 auto; 
    }
  
    .review-description {
      font-size: 11px; 
      text-align: center; 
     
    }
  
    .user-name {
      font-size: 12px; 
      text-align: center; 
    }
  
    .line {
      width: 25px; 
      margin: 0 auto; 
    }
  
    .icon-size {
      font-size: 12px !important; 
      padding: 5px 7px; 
    }
  
    
    .google-review {
      padding: 8px;
    }
  }
  