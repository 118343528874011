.inst 
{
     font-weight: bold;
      font-size: 40px;
       margin-left: 80px;
}
.inst1 
{
     color: black;
      
       margin-left: 80px;
}
.instructor img{
     width: 100%;
       height: auto;
        border-radius: 5px;
}
.teaching button{
    margin-left: 80px;
}
@media (max-width: 500px) {
   
    .instructor img {
     display: none;
    }
  
    .inst 
    {
         font-weight: bold;
          font-size: 40px;
         margin-left: 0px;
         margin-top: -50px;
    }
    .inst1 
{

       margin-left: 0px;
}
.teaching button{
    margin-left: 0px;
}
  }