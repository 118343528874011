*{
    margin: 0;
    padding: 0;
}

#slider .swiper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

#slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

#slider .swiper-slide img {
    /* display: block; */
    width: 100%;
    height: 500px;
    object-fit: fill;
    margin-right: 0px !important;
    margin-bottom: 10px;
}

#slider .swiper-button-next,
#slider .swiper-button-prev {
    color: rgb(0, 0, 0) !important;
    background: #ffffff !important;
    box-shadow: 0 0 35px #9eceee !important;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 20px;
    border: 0;
}

#slider .swiper-button-next:hover,
#slider .swiper-button-prev:hover {

    transition: 0.2s ease-out;
    transform: translateY(-0.1em) !important;
}

#slider .swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 20px !important;
    font-weight: 900;
}
#slider.cards{
    z-index: 1000;
    margin-top: -140px;
    position: relative;
}
#slider .desk-img{
    display: block;
}
#slider .mobile-img{
    display: none;
}
@media screen and (max-width: 480px) {
    .desk-img{
        display: none;
    }
    #slider .swiper-slide img {
       height:300px;
        margin-bottom: 10px;
        margin-right: 0px;
    }
    
  }

  @media screen and (max-width: 480px) {
    .mobile-img{
        display: block;
    }
  }

