#QandA .QandA-heading img{
    width: 95px;
    margin-left: 17px;
    margin-top: 9px;
}
.QandA-form .input-search{
    width: 284px;
    height: 49px;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.QandA-form button{
    border-bottom-right-radius: 23px;
    border-top-right-radius: 23px;
    background-color: #f04c24;
}