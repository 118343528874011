
#Ct {
    text-align: center;
  }
  
  .corpfeatures {
    background-color: white;
    padding: 20px 0;
  }
  
  .heading {
    color: #ff5722;
    font-weight: bold;
  }
  
  .cpt .card {
    margin-top: 20px;
    transition: transform 0.3s ease;
  }
  
  .cpt .card:hover {
    transform: scale(1.05);
  }
  
  .card-detail ul {
    padding-left: 20px;
    list-style: none;
  }
  
  .card-detail ul li {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .cpt .row {
      flex-direction: row;
    }
  
    .cpt .card {
      width: 100%;
      /* margin-bottom: 20px; */
    }

    .card-detail li {
      font-size: 15px !important;
    }
  }
  
  @media (max-width: 991px) {
    .cpt .row {
      flex-direction: row;
    }
  
    .cpt .card {
      width: 100%;
      /* margin-bottom: 20px; */
    }

    .card-detail li {
      font-size: 5px;
    }
  }
  
  @media (min-width: 992px) {
    .cpt .row {
      display: flex;
      justify-content: space-between;
    }
  
    .cpt .card {
      width: 50%;
    }
  }
  