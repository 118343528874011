
  .welcomeContainer {
    width: 100%;
    margin-top: 70px;
    margin-left:200px;
    background-size: cover;
    background-position: center;
  }
  
  .welcomeContent {
    padding: 16px;
    text-align: left;
  }
  
  .welcomeHeading {
    font-weight: bold;
    color: #ffffff;
  }
  
  .welcomeText {
    font-weight: bold;
    color: #ffffff;
    font-size: 18px;
  }
  
  
  .topBarWelcome {
    margin-bottom: 10px;
  }
  
  .topBar {
    display: flex;
    align-items: center;
    position: fixed;
    background-color: #ffffff;
    height: 10%;
    width: 100%;
    padding: 0 16px;
    top: 0;
    z-index: 1;
    justify-content: flex-end;
  }
  
  .flexibleSpace {
    flex-grow: 0.04;
  }
  
  .userDpImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .userInfo {
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
  }
  
  .userInfoText {
    max-width: 180px;
    align-items: center !important;
  }
  
  .userName {
    font-size: 14px;
    line-height: 1.2;
  }
  
  .userPosition {
    font-size: 12px;
    line-height: 1.2;
  }
  
  .flag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 8px;
  }
  
  .flagContainer {
    cursor: pointer;
    width: 100%;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 1;
    right: 1;
    z-index: 999;
    width: auto;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-top: 5px;
    align-content: end;
  }
  
  .dropdown > .MuiMenuItem-root {
    padding: 8px 16px;
  }
  
  .badge {
    color: #6d0954 !important;
    cursor: pointer !important;
    margin-right: 2px;
  }
  
  .notificationModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    padding: 16px;
    border-radius: 4px;
  }
  
  .notificationModalTitle {
    margin-bottom: 16px;
    align-items: center;
  }
  
  .notificationList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .notificationItem {
    margin-bottom: 8px;
    margin-bottom: 8px;
    word-break: break-word;
  }
  .sidebarMenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 999;
    padding: 10px;
    overflow-y: auto;
  }
  
  @media (max-width: 912px) {
    .topBar {
      padding: 0 16px;
      width: 100%;
      height: 7%;
    }
  
    .userDpImage {
      width: 40px;
      height: 40px;
      margin-right: 5px;
      border-radius: 50%;
      margin-left: 5px;
    }
    .userInfoText {
      max-width: auto;
    }

    .welcomeContainer
    {
      margin-top:15%;
      margin-left:0;
    }
  
    .userName {
      font-size: 12px;
      line-height: 1.2;
    }
  
    .userPosition {
      font-size: 10px;
      line-height: 1.2;
    }
    .flexibleSpace {
      flex-grow: 0.15;
    }
  }
  
  
  
  