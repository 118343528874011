.become-partner {
  width: 100%;
  min-height: 100vh; /* Allow the height to grow based on content */
  background: rgb(246, 210, 188); /* Background color */
  clip-path: ellipse(100% 70% at 50% 0%); /* Clip-path for styling */
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Ensure no content overflows */
}

.become-partner .content {
  position: absolute;
  top: 20px;
  left: 110px;
  max-width: 37%;
  text-align: left;
}

.become-partner img {
  position: absolute;
  top: 20px;
  right: 75px;
  width: 100%;
  max-width: 450px;
  height: auto;
  margin: 10px 30px;
}

.become-partner h1 {
  letter-spacing: .9px;
  font-size: 3rem;
  font-weight: 700;
  color: black;
}

.become-partner h2 {
  font-size: 1.5rem;
  color: black;
  font-weight: 600;
}

.become-partner .btn-primary {
  font-size: 1.25rem;
  border: none;
  padding: 0.75rem 1.5rem;
  background-color: rgb(58, 61, 78);
  color: white;
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .become-partner img {
    margin: 10px 20px;
    max-width: 330px; 
  }

  .become-partner .content {
    max-width: 40%;
  }

  .become-partner {
    clip-path: ellipse(100% 75% at 50% 0%);
  }
}

@media (max-width: 836px) {
  .become-partner .content,
  .become-partner img {
    position: static;
    max-width: 65%; /* Make the content wider */
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 20px;
  }

  .become-partner .content {
    transform: translate(23%, 10%);
  }

  .become-partner h1 {
    font-size: 2rem;
  }

  .become-partner h2 {
    font-size: 1.25rem;
  }

  .become-partner {
    clip-path: ellipse(100% 80% at 50% 0%);
  }
}

@media (max-width: 768px) {
  .become-partner .content,
  .become-partner img {
    position: static;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 20px;
    max-width: 60%; /* Further adjustment for smaller screens */
  }

  .become-partner .content {
    transform: translate(28%, 10%);
  }


  .become-partner h1 {
    font-size: 1.8rem;
  }

  .become-partner h2 {
    font-size: 1.25rem;
  }

  .become-partner {
    clip-path: ellipse(100% 85% at 50% 0%);
  }
}

@media (max-width: 626px) {
  .become-partner .content,
  .become-partner img {
    position: static;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 10px;
    max-width: 100%;
  }

  .become-partner .content {
    transform: translate(6%, 10%);
  }

  .become-partner img {
    margin-top: 50px;
    margin-left: 20px !important;
  }

  .become-partner h1 {
    font-size: 1.6rem;
  }

  .become-partner h2 {
    font-size: 1.2rem;
  }

  .become-partner {
    clip-path: ellipse(100% 90% at 50% 0%);
  }
}
