/* NewPasswordForm.module.css */

.pageContainer {
  display: flex;
  height: 100vh;
}

.topImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
}

.loginContainer {
  flex: 1;
  display: flex;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
}

.rightContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightImage {
  width: 100%;
  height: 100%;
}

.logoContainer {
  margin-bottom: 20px;
}

.logo {
  width: 92px;
  height: 77px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
  font-size: 14px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.inputContainer {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items:center;
  margin-left:30px;
}

.inputContainer input[type='password'] {
  flex:1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 16px;
  margin-left:35px;
  margin-bottom: 10px;
}

.lockIcon {
  /* Update the position and color of the lock icon */
  position: absolute;
  left: 0;
  top: calc(50% -0.5rem);
  color: black !important; 
  font-size: 18px;
}

.inputBorder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f04c24;
  transform-origin: left;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.inputContainer:focus-within .inputBorder {
  transform: scaleX(1);
}

.lockIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #f04c24;
  font-size: 18px;
}

.errorMessage {
  margin-top: 5px;
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
  text-align: left;
  width: 100%;
}

.button {
  padding: 10px 20px;
  background-color: #f04c24;
  color: white;
  width: 40%;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #f04c24;
}

.linkButton {
  color: #f04c24;
  text-decoration: none;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .topImage {
    width: 200px;
    height: 200px;
  }
  .loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .leftContainer {
    flex: 1;
    width: 100%;
  }

  .rightContainer {
    display: none; /* Hide the right container on mobile screens */
  }

  .form {
    width: 90%;
    max-width: 320px;
  }

  .text {
    font-size: 12px;
    margin-bottom: 15px;
  }
}