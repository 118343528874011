.sidebar {
  width: 200px !important;
  background-color: #ffffff;
  padding: 10px 8px 10px 8px !important;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  margin-top: 0;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  z-index: 999 !important;
}

.logo {
  width: 80px;
  height: 70px;
}

.hamburgerIcon {
  color: #f04c24 !important;
  justify-content: center !important;
  padding: 2px !important;
  font-size: 20px !important;
  cursor: pointer !important;
}

.subtitle {
  margin-bottom: 4px;
  margin-left: 8px !important;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.link:hover {
  text-decoration: underline;
}

.listItemIcon {
  min-width: 24px !important;
  font-size: 16px !important;
  margin-right: 4px !important;
}

.listItemText {
  font-size: 16px;
  flex: 1;
  padding-left: 4px;
  height: 25px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.sectionBreak {
  margin-bottom: 8px;
  border-bottom: 1px solid #e9ecef;
}

.selectedButton {
  background-color: #f04c24!important;
  color: #ffffff !important;
  margin-right: 2px !important;
  margin-left: 2px 4px !important;
}

.selectedIcon {
  color: #ffffff !important;
  margin-right: 0% !important;
}

.chevron {
  margin-left: auto;
  color: black;
  font-size: 17px !important;
}

.selectedButton .chevron {
  color: #ffffff;
}

/* Override MuiListItemIcon styles */
.MuiListItemIcon-root {
  width: 3px !important; /* Reset the min-width */
  padding-right: 0px !important; /* Reduce the right padding */
}

@media (max-width: 912px) {
  .sidebar {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 999;
    padding: 10px;
    top: 0;
    left: -100%;
    transition: left 0.3s ease-in-out;
  }

  .sidebar.open {
    left: 0;
  }

  .hamburgerIcon {
    position: fixed !important;
    top: 2%!important;
    left: 2%!important;
    z-index: 1000 !important;
    color: #f04c24;
  }

  .logoContainer {
    display: none;
  }

  .listItemIcon,
  .chevron {
    color: #000000 !important;
  }
}
