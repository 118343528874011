/* LoginForm.module.css */

.pageContainer {
    display: flex;
    height: 100vh;
  }

.topImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
}

  .loginContainer {
    flex: 1;
    display: flex;
  }
  
  .leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  .rightContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rightImage {
    width: 100%;
    height: 100%;
  }
  
  .logoContainer {
    margin-bottom: 20px;
  }
  
  .logo {
    width: 92px;
    height: 77px;
  }
  
  .loginText {
    font-size: 24px;
    margin-bottom: 50px;
    width: 200px;
    text-align: center;
  }
  
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .input {
    margin-bottom: 10px;
    padding: 5px;
    border: 2px solid #f04c24;
    height: 40px;
    width: 100%;
  }
  
  .button {
    padding: 5px 10px;
    background-color: #f04c24;
    color: white;
    width: 40%;
    margin-bottom: 10px;
    margin-top:30px;
  }
  
  .errorMessage {
    margin-top: 5px;
    margin-bottom: 10px;
    color: red;
    font-size: 14px;
    text-align: left;
    width: 100%;
  }
  
  /* Additional Styles for the new elements */
  .profileImageContainer {
    margin-bottom: 20px;
  }
  
  .profileImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .profileText {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .message {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .forgotPassword {
    font-size: 14px;
    text-align: center;
    color: #f04c24;
    cursor: pointer;
  }
  .linkButton {
    color: #f04c24;
    text-decoration: none;
  }
  
  /* Media Query for Mobile Screens */
  @media (max-width: 768px) {

    .topImage {
      width: 200px;
      height: 200px;
    }
    .loginContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .leftContainer {
      flex: 1;
      width: 100%;
    }
  
    .rightContainer {
      display: none;
    }
  
    .loginForm {
      width: 90%;
      max-width: 320px;
    }
  
    .profileImageContainer {
      margin-bottom: 10px;
    }
  
    .profileImage {
      width: 60px;
      height: 60px;
    }
  
    .profileText {
      font-size: 16px;
      margin-bottom: 5px;
    }
  
    .message {
      font-size: 12px;
      margin-bottom: 15px;
    }
  
    .forgotPassword {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }