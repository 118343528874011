
.footerContainer {
  background-color: whitesmoke;
  color: black;
  padding-top: 20px;
}

.topSection {
  background-color: whitesmoke;
  padding: 20px 0;
  
}



.logoImage {
  width: 150px;
  height:50px;
  
}

.description {
  font-size: 14px;
  line-height: 1.6;
}

.sectionTitle {
  color: #f04c24;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  
}

.list {
  list-style: none;
  padding-left: 0;
}


.list li {
  font-size: 14px;
  line-height: 1.8;
}
.list li:hover{
  color: #bc3918;
  cursor: pointer;
}
.company{
  padding-left: 70px;
}

.workInfo {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
}

.workInfo li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.workInfo li:hover{
  color: #bc3918;
  cursor: pointer;
}
.workInfo li svg {
  margin-right: 10px;
  color: #f04c24;
}

.socialIcons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}


.socialIcons svg {
  color: #f04c24;
  cursor: pointer;
}

.bottomSection {
  background-color: #f04c24;
  padding: 15px 0;
}

.bottomLinks {
  text-align: right;
}

.bottomLinks a {
  color: white;
  margin-left: 15px;
  font-size: 14px;
}

.bottomLinks a:hover {
  color: #bc3918;
}
.footerRow ul li {
  color: black;
}
.footerRow ul li:hover{
  color: #bc3918;
}
@media (min-width: 768px) {
  .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
  }
  .tab{
    display: none !important;
  }
  
}
@media (max-width: 768px) {

  .company{
    padding-left: 10px ;
  }
  .sectionTitle {
   
    margin-top: 20px;
  }
  
}