




#imageContainer {
    height: 400px;
}

#trainingBtn {
    background-color: #f04c24;
    color: black;
}

#trustedBy {
    display: flex;
   
}

#rightContent {
    margin: 0;
    gap: 10px;
}

#heading_2 {
    font-size: 30px;
    font-weight: 500;
}

#heading_3 {
    font-size: 16px;
    margin-top: 10px;
}

.article {
    display: block;
}

.heading {
    color: #f04c24;
    text-align: center;
}
.cpbanner{
    display: block;
}
.cpbanner-mobile{
    display: none;
}
img {
    width: 45px;
}
