
#cards{
    margin-top: "-110px";
    z-index: "2";
    position: "relative";
}
#cards .row{
        margin-top:-25px;
        z-index:1;
        position:relative;
}
.card{
    cursor: pointer;
}
.pa-m{
   padding: 10px 20px;
}
@media screen and (max-width: 480px) {
    #cards .row {
      display: none !important;
    }
}
@media screen and (max-width: 1000px) {
    #cards .row {
      display: none !important;
    }
  }