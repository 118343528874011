.searchContainer {
  position: relative;
  border-bottom: 1px solid #B9B9B9;
  margin-bottom: 30px;
  margin-top: 2%;
  background-color: #E9ECEF;
  width: 100%;
}

.searchContainer input[type="text"] {
  width: 250px;
  padding: 8px;
  border: 1px solid #ADB5BD;
  border-radius: 4px;
  margin-right: 7px;
  padding-right: 10px;
  padding-left: 30px;
}

.searchSymbol {
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  color: #ADB5BD;
  left: 10px;
}

.searchBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 30px;
  max-width: fit-content;
  justify-content: space-between; 
}

.addButton {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #f04c24;
  color: #ffffff;
  border: 1px #f04c24;
  border-radius: 6px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  margin-top: -2px;
  margin-left: 786px; 
  margin-right: 20px; 
}

.buttonIcon {
  margin-right: 0px;
  margin-left: 7px;
}

.arrowContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  top: -0.1rem;
  right: 45px;
}

.arrowRight {
  border: solid #ADB5BD;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
}

.arrowDown {
  border: solid #ADB5BD;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 20%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
  z-index: 1;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #EBE7E9;
}

.clearIcon {
  position: relative;
  top: 40%;
  right: 55px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #f04c24;
}

/* Media Queries */
@media (max-width: 912px) {
  .searchContainer {
    border-bottom: 1px solid #B9B9B9;
    margin-bottom: 30px;
    margin-top: 10% !important;
    background-color: #E9ECEF;
    height: auto;
    padding: 2px !important;
    padding-left: 0 !important;
  }

  .searchContainer input[type="text"] {
    width: 100%;
  }

  .addButton {
    margin-left: 0;
  }

  .dropdown {
    width: 60%;
  }
}


