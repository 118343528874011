/*@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600;700;800;900&display=swap');*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    user-select: none;
  }
/*html{
    font-size: 10px;
}*/

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: unset;
}

.grid {
    display: grid;
}

.mx-auto {
    margin-right: auto;
    margin-left: auto;
}

.btn btn-link {
    background-color: --blue-600;
}