.chartContainer {
  margin-top: 100px;
  margin-left: 40px;
  overflow: hidden !important;
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;/* Add negative margin to counteract the default grid padding */
}

.chartCard {
  width: 80%;
  height: 100%;
  border-radius: 28px !important;
  margin-left: 10%;
}

.pieChart {
  height: 60%;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
}

.chartTitle {
  color: #2b3674 !important;
}

.dropdownContainer {
  position: relative;
  top: 0px;
  right: 5%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.dropdownContainer .MuiFormControl-root {
  width: 5%;
}

.dropdownContainer .MuiSelect-select {
  color: #a3aed0 !important;
  border-bottom: 0.3px solid #a3aed0;
}

/* Media queries for smaller screens */
@media (max-width: 912px) {
  .chartContainer {
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .gridContainer {
    justify-content: center;
  }

  .chartCard {
    width: 90%;
    margin-left: 1% !important;
    margin-right: 5% !important;
  }

  .pieChart {
    height: 50%;
    width: 70%;
  }

  .dropdownContainer .MuiFormControl-root {
    width: 10%;
  }
}

/* Media queries for even smaller screens */
@media (max-width: 480px) {
  .chartContainer {
    margin-top: 80px;
    margin-left: 5%;
  }

  .chartCard {
    width: 100%;
    margin-left: 10%;
  }

  .pieChart {
    height: 60%;
    width: 80%;
  }

  .dropdownContainer .MuiFormControl-root {
    width: 20%;
  }
}